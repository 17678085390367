import { Fragment, lazy, Suspense } from 'react';
import { Navigate, Route } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
// import GlobalGuard from './components/GlobalGuard';
import SplashScreen from './components/SplashScreen';
import MainLayout from './layouts/MainLayout';
// import Dashboard from './views/Dashboard';
import Login from './views/Login';
import SiteSelection from './views/Login/siteSelection';
// import Login from './views/Login';

const Page404 = lazy(() => import('./components/Page404'));

export const renderRoutes = (routes = []) => {
    return routes.map((route, i) => {
        const { component: Component, guard, props = {}, routes = [], index = false, path } = route || {};
        const Guard = guard || Fragment;

        return (
            <Route
                key={i}
                index={index}
                path={path}
                element={
                    <Guard>
                        <Suspense fallback={<SplashScreen />}>
                            <Component {...props} />
                        </Suspense>
                    </Guard>
                }
            >
                {routes.length ? renderRoutes(routes) : null}
            </Route>
        );
    });
};

const routes = [
    {
        path: '/',
        component: () => <Navigate to="/ims/operations/job-cards" />
    },
    {
        path: '/ims/login',
        component: Login
    },
    {
        path: '/ims/select-site',
        component: SiteSelection,
        guard: AuthGuard
    },
    {
        path: '/ims',
        component: MainLayout,
        guard: AuthGuard,
        routes: [
            {
                index: true,
                component: () => <Navigate to="/ims/operations/job-cards" />
            },
            {
                path: '/ims/administration/users',
                component: lazy(() => import('./views/Administration/Users/index')),
            },
            {
                path: '/ims/administration/sites',
                component: lazy(() => import('./views/Administration/Sites/index')),
            },
            {
                path: '/ims/administration/inventory-items',
                component: lazy(() => import('./views/Administration/InventoryItems/index')),
            },
            {
                path: '/ims/administration/vehicles',
                component: lazy(() => import('./views/Administration/Vehicles/index')),
            },
            {
                path: '/ims/administration/customers',
                component: lazy(() => import('./views/Administration/Customers/index')),
            },
            {
                path: '/ims/operations/job-cards',
                component: lazy(() => import('./views/Operations/JobCards/index')),
            },
            {
                path: '/ims/operations/goods-received-vouchers',
                component: lazy(() => import('./views/Operations/GRVs/index')),
            },
            {
                path: '/ims/reports/inventory-movements',
                component: lazy(() => import('./views/Reports/InventoryMovements/index'))
            },
            {
                path: '/ims/reports/diesel-usage',
                component: lazy(() => import('./views/Reports/DieselUsage/index'))
            },
            {
                path: '/ims/reports/sales-analysis',
                component: lazy(() => import('./views/Reports/SalesAnalysis/index'))
            },
            {
                path: '/ims/reports/payments-report',
                component: lazy(() => import('./views/Reports/PaymentsReport/index'))
            },
            {
                path: '*',
                component: Page404
            }
        ]
    }
];

export default routes;
