import { useTheme } from '@emotion/react'
import { Box, Button, Card, CardActions, CardContent, CardHeader, Modal, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { AlertCircle } from 'react-feather';
import { useLocation } from 'react-router-dom';
import { useAuth } from './authentication/useAuth';
import IF from './components/IF';

const PasswordPrompt = ({ open, stay, handleLogout }) => {

    const theme = useTheme();

    const location = useLocation();
    
    const { user, login, setSession } = useAuth();
    const [ error, setError ] = useState(null);
    
    const [password, setPassword] = useState('');
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await login({ userName: user.userName, password, location: location.pathname });
            stay();
        }
        catch (error) {
            setError(error.message);
        }
    }

    useEffect(() => {
        if(open) {
            setSession(null, null, true);
            setPassword('');
            setError(null);
        }
    }, [user, open, setSession])

    return (
        <Modal
            open={open}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
            className="no-tracking"
        >
            <Card sx={{ minWidth: '30vw', border: 2, borderColor: theme.palette.error.main }}>
                <form onSubmit={handleSubmit}>
                    <CardHeader title="User Activity Timeout" />
                    <CardContent>
                        <Stack gap={4}>
                            <Typography variant='body1'>
                                There has been no activity for the last 15 minutes, please enter your password to continue. You will be logged out if you do not enter your password within 2 minutes.
                            </Typography>
                            <TextField
                                size="small"
                                variant="outlined"
                                autoComplete={null}
                                label="Password"
                                type="password"
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <IF condition={error}>
                                <Box display="flex" gap={2} alignItems="center">
                                    <AlertCircle size={16} color={theme.palette.error.main} />
                                    <Typography variant="caption" color="error.main">
                                        {error}
                                    </Typography>
                                </Box>
                            </IF>
                        </Stack>
                    </CardContent>
                    <CardActions>
                        <Stack direction="row" justifyContent="space-between" width="100%" padding={1}>
                            <Button size="small" variant="outlined" color="error" onClick={handleLogout} >Logout</Button>
                            <Button className="no-tracking" size="small" variant="contained" color="primary" type="submit">Submit</Button>
                        </Stack>
                    </CardActions>
                </form>
            </Card>
        </Modal>
    )
}

export default PasswordPrompt