import jwt_decode from 'jwt-decode';
import { createContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SplashScreen from '../components/SplashScreen';
import useLocalStorage from '../hooks/useLocalStorage';
import instance from '../utils/imsAxios';

const authContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, isInitialised] = useProvideAuth();
    return isInitialised ? <authContext.Provider value={auth}>{children}</authContext.Provider> : <SplashScreen />;
};

const useProvideAuth = () => {
    const navigate = useNavigate();
    const { pathname = '/', state } = useLocation();
    const [storedToken, setStoredToken] = useLocalStorage('token', null);
    const [storedUser, setStoredUser] = useLocalStorage('user', null);
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);
    const [viewable, setViewable] = useState({ modules: [] });
    const [editable, setEditable] = useState({ modules: [] });
    const [deletable, setDeletable] = useState({ modules: [] });
    const [isInitialised, setIsInitialised] = useState(false);

    const setupUserPermissions = (user) => {
        const { userPermissions = [] } = user || {};
        let viewableModules = [];
        let editableModules = [];
        let deletableModules = [];
        userPermissions.forEach((module) => {
            if(module.view) {
                viewableModules.push(module.module)
            }
            if(module.edit) {
                editableModules.push(module.module)
            }
            if(module.del) {
                deletableModules.push(module.module)
            }
        });
        if(user._id) viewableModules.push('select-site')
        setViewable([...new Set(viewableModules)]);
        setEditable([...new Set(editableModules)]);
        setDeletable([...new Set(deletableModules)]);
    };

    const setSession = (authToken, user, inactivity) => {
        if (authToken && user) {
            instance.defaults.headers.common.Authorization = authToken;
            setStoredToken(authToken);
            setStoredUser(user);
        } else {
            delete instance.defaults.headers.common.Authorization;
            setStoredToken(null);
            setStoredUser(null);
            if(!inactivity) localStorage.setItem('selectedSite', null)
        }
    };

    const login = async ({ userName, password, location } = {}) => {
        let from = (localStorage.getItem('selectedSite')?.siteId && state) ? state.from?.pathname : '/ims/select-site';
        
        if (!isValidToken(storedToken)) {
            setSession(null, null, !!location);
        }
        try {
            const {
                data: { user, token }
            } = await instance.post('/login', { userName, password });
            setSession(token, user);
            setUser(user);
            setToken(token);
            setupUserPermissions(user);
            return navigate(location ?? from, { replace: true });
        } catch (error) {
            if(!location) {
                setUser(null);
                setToken(null);
                setSession();
            }
            throw new Error('Invalid Username / Password!');
        }
    };

    const logout = () => {
        setSession();
        return navigate('/ims/login');
    };

    const canView = (module) => {
        // if (user.roleDescription === 'Administrator') return true;
        // if (user.roleDescription === 'Standard') return true;
        return viewable?.includes(module);
    };

    const canEdit = (module) => {
        // if (user.roleDescription === 'Administrator') return true;
        return editable?.includes(module);
    };

    const canDelete = (module) => {
        // if (user.roleDescription === 'Administrator') return true;
        return deletable?.includes(module);
    };

    const isValidToken = (accessToken) => {
        if (!accessToken) {
            return false;
        }

        const decoded = jwt_decode(accessToken);
        const currentTime = Date.now() / 1000;
        const res = decoded.exp > currentTime;
        return res;
    };

    const refreshToken = async () => {
        const {
            data: { user, token }
        } = await instance.post('/login');
        // set new session with new token and user
        setSession(token, user);
        setUser(user);
        setToken(token);
        // finish setting up User and Permissions
        setupUserPermissions(user);
    };

    useEffect(() => {
        // initialise authentication to check if valid token stored in local storage, redirect if its not
        (async () => {
            try {
                if (storedToken) {
                    // if there is a local token, set the session to attach it to the req headers
                    setSession(storedToken, storedUser);
                    const tokenIsValid = isValidToken(storedToken);
                    // send a login request to the backend to check the token is valid. If valid return a new token
                    // with extended expiration
                    if (tokenIsValid) {
                        refreshToken();
                        setUser(storedUser);
                        setToken(storedToken);
                        setupUserPermissions(storedUser);
                    } else {
                        setUser(null);
                        setToken(null);
                        setSession();
                    }
                    setIsInitialised(true);
                    // if path is /login then reroute to /dashboard
                    if (pathname === '/ims/login') {
                        return navigate('/ims/operations/job-cards', { replace: true });
                    }
                } 
                else {
                    setUser(null);
                    setToken(null);
                    setSession();
                    setIsInitialised(true);
                    return navigate('/ims/login');
                }
            } catch (error) {
                setUser(null);
                setToken(null);
                setSession();
                setIsInitialised(true);
                return navigate('/ims/login');
            }
        })();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [
        {
            user,
            token,
            companyId: user?.companyId,
            login,
            logout,
            canView,
            canEdit,
            canDelete,
            setSession,
            supplierUser: user?.role === 'Standard' || user?.role === 'Administrator',
            supplierAdmin: user?.role === 'Administrator',
            isValidToken,
            refreshToken,
            siteAccess: user?.siteAccess
        },
        isInitialised
    ];
};

export default authContext;
