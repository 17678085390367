import { createSlice } from '@reduxjs/toolkit';
import instance from '../../../utils/imsAxios';

const initialState = {
    grvs: []
};

export const GRVSlice = createSlice({
    name: 'grvs',
    initialState,
    reducers: {
        setAllGRVs: (state, action) => {
            state.grvs = action.payload;
        }
    }
});

export const getAllGRVs = (companyId, siteId) => async (dispatch) => {
    try {
        if (!companyId) return dispatch(GRVSlice.actions.setAllGRVs(initialState.sites));
        const response = await instance.get('/grvs', { params: { companyId: companyId, siteId: siteId } });
        dispatch(GRVSlice.actions.setAllGRVs(response.data));
    } catch (error) {
        return dispatch(GRVSlice.actions.setAllGRVs(initialState.sites));
    }
};

export const {setAllGRVs} = GRVSlice.actions;

export default GRVSlice.reducer;
