import { alpha, createTheme } from '@mui/material';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#4D9D80',
            dark: '#2f614f'
        },
        secondary: {
            main: '#4E4D9D'
        },
        accent: {
            main: '#4D9D80',
            dark: '#7c9b2a'
        },
        action: {
            active: '#4D9D80',
            focus: '#4D9D80',
            selected: '#4D9D80',
            hover: alpha('#4D9D80', 0.5)
        },
        categories: {
            administration: {
                main: '#1eaf3e',
                light: '#24d54b'
            },
            'operations-planning': {
                main: '#812ba4',
                light: '#9f35ca'
            },
            reports: {
                main: '#ff3737',
                light: '#ff4437'
            }
        },
        categoryColors: ['#1d4e71', '#0f2a3d']
    }
});
