import { AppBar, Autocomplete, Hidden, Stack, TextField, Toolbar, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import dayjs from 'dayjs';
import WeekOfYear from 'dayjs/plugin/weekOfYear';
import React from 'react';
import { Menu } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../../authentication/useAuth';
import { setDrawerOpen, setSelectedSite } from '../../../store/global';
import ProfileButton from './ProfileButton';
import UploadButton from './UploadButton';

const TopBar = () => {
    dayjs.extend(WeekOfYear);
    const theme = useTheme();
    const dispatch = useDispatch();
    const { siteAccess = [] } = useAuth();
    const { siteSelected } = useSelector(state => state.global)
    // const isTablet = useIsTablet();
    // const isOnline = useOnlineStatus();

    return (
        <>
            <AppBar
                position="fixed"
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, height: '48px' }}
                color="primary"
            >
                <Toolbar variant="dense" sx={{ justifyContent: 'flex-end' }}>
                    <Hidden mdUp>
                        <Box position="absolute" left="10px">
                            <Menu onClick={() => dispatch(setDrawerOpen())} />
                        </Box>
                    </Hidden>
                    <Hidden mdDown>
                        <Box position="absolute" left="20px">
                            <Stack direction="row" justifyContent="center">
                                <Typography
                                    fontSize={24}
                                    color={theme.palette.getContrastText(theme.palette.primary.main)}
                                    textAlign="left"
                                >
                                    Inventory Management System
                                </Typography>
                            </Stack>
                        </Box>
                    </Hidden>
                    <Box display="flex" sx={{ gap: 2 }} alignItems="center">
                        <Stack direction="row" display="flex" gap={2} justifyContent="flex-start" alignItems="center">
                            <UploadButton />
                            <Stack direction={"row"} gap={1} justifyContent="flex-start" alignItems="center">
                                <Hidden mdDown>
                                    <Typography sx={{whiteSpace: 'nowrap'}}>{`Current Site:`}</Typography>
                                </Hidden>
                                <Autocomplete 
                                    options={siteAccess ?? []}
                                    value={siteSelected ?? JSON.parse(localStorage.getItem('selectedSite'))}
                                    size="small"
                                    disableClearable
                                    getOptionLabel={(option) => option.siteName ?? ""}
                                    isOptionEqualToValue={(option, value) => option === value}
                                    onChange={(event, option) => {
                                        const currentValue = siteSelected ?? JSON.parse(localStorage.getItem('selectedSite'));
                                        if(currentValue.siteId !== option.siteId) {
                                            localStorage.setItem('selectedSite', JSON.stringify(option));
                                            dispatch(setSelectedSite(option));
                                            window.location.reload(false);
                                        }
                                    }}
                                    sx={{width: '200px'}}
                                    renderInput={(params) => 
                                        <TextField 
                                            {...params} 
                                            color="secondary" 
                                            sx={{
                                                backgroundColor:"white",
                                                borderRadius: '4px'
                                            }}
                                        />
                                    }
                                />
                            </Stack>
                        </Stack>
                        {/* <IF condition={!isTablet}>
                            <Stack sx={{ marginRight: 4 }} direction={'row'} gap={1} alignItems="center">
                                <Typography variant="subtitle1">{`Week ${dayjs().week()}`}</Typography>
                                <Divider
                                    orientation="vertical"
                                    sx={{ height: theme.spacing(3), borderWidth: '1px', borderColor: textColor }}
                                />
                                <Typography variant="subtitle2">{`${dayjs().format('ddd, DD MMM YYYY')}`}</Typography>
                            </Stack>
                        </IF> */}
                        {/* <IF condition={isTablet}>
                            <Stack sx={{ marginRight: 2 }} direction={'row'} gap={1} alignItems="center">
                                <Divider
                                    orientation="vertical"
                                    sx={{ height: theme.spacing(3), borderWidth: '1px', borderColor: textColor }}
                                />
                                <Stack>
                                    <Typography
                                        variant={isTablet ? 'subtitle2' : 'subtitle1'}
                                    >{`Week ${dayjs().week()}`}</Typography>
                                    <Typography variant={isTablet ? 'caption' : 'subtitle2'}>{`${dayjs().format(
                                        'ddd, DD MMM YYYY'
                                    )}`}</Typography>
                                </Stack>
                            </Stack>
                        </IF> */}
                        {/* <ToggleButton
                            onClick={() => dispatch(toggleNotifications())}
                            size="small"
                            value="notifications"
                            selected={notificationsOpen}
                            sx={{
                                border: 'none',
                                borderRadius: '50%'
                            }}
                        >
                            <Bell color={textColor} size={28} />
                        </ToggleButton>
                        <SettingsButton textColor={textColor} /> */}
                    <ProfileButton sx={{ padding: '0px', mr: '10px' }} />
                    </Box>
                </Toolbar>
            </AppBar>
            {/* <IF condition={!isOnline}>
                <AppBar
                    sx={{
                        zIndex: (theme) => theme.zIndex.drawer - 1,
                        height: '8px',
                        top: '48px',
                        alignItems: 'center'
                    }}
                    position="fixed"
                    color="error"
                >
                    <Box display="flex">
                        <Box
                            sx={{
                                width: 0,
                                height: 0,
                                borderTop: `20px solid ${theme.palette.error.main}`,
                                borderLeft: '20px solid transparent'
                            }}
                        />
                        <Box bgcolor="error.main" width="120px" textAlign="center">
                            <Typography variant="body2">Offline</Typography>
                        </Box>
                        <Box
                            sx={{
                                width: 0,
                                height: 0,
                                borderTop: `20px solid ${theme.palette.error.main}`,
                                borderRight: '20px solid transparent'
                            }}
                        />
                    </Box>
                </AppBar>
            </IF> */}
        </>
    );
};

export default TopBar;
