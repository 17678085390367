import { createSlice } from '@reduxjs/toolkit';
import instance from '../../../utils/imsAxios';

const initialState = {
    inventoryItems: [],
    allInventoryItems: []
};

export const inventorySlice = createSlice({
    name: 'inventory',
    initialState,
    reducers: {
        setAllItems: (state, action) => {
            state.inventoryItems = action.payload;
        },
        setAllItemsFromAllSites: (state, action) => {
            state.allInventoryItems = action.payload
        },
        updateItem: (state, action) => {
            const { itemName } = action.payload;
            const index = state.inventoryItems.findIndex(i => i.itemName === itemName);
            if(index > -1) {
                state.inventoryItems[index] = action.payload;
            }
            else {
                state.inventoryItems.push(action.payload);
            }
        },
        deleteItem: (state, action) => {
            const index = state.inventoryItems.findIndex(item => item.itemName === action.payload);
            state.inventoryItems.splice(index, 1);
        },
        updatePricing: (state, action) => {
            action.payload.forEach(element => {
                const index = state.inventoryItems.findIndex(i => i.itemName === element.itemName);
                const newPrice = element.newPrice;
                let newData = element;
                if(newPrice) newData.currentUnitPrice = newPrice;
                state.inventoryItems[index] = newData;
            });
        }
    }
});

export const getAllItems = (companyId, siteId) => async (dispatch) => {
    if (!companyId) return dispatch(inventorySlice.actions.setAllItems(initialState.items));
    try {
        const response = await instance.get('/items', { params: { companyId: companyId, siteId: siteId } });
        dispatch(inventorySlice.actions.setAllItems(response.data));
    } catch (error) {
        return dispatch(inventorySlice.actions.setAllItems(initialState.items));
    }
};

export const getAllItemsFromAllSites = (companyId) => async (dispatch) => {
    if(!companyId) return dispatch(inventorySlice.actions.setAllItemsFromAllSites(initialState.allInventoryItems));
    try {
        const response = await instance.get('/items/all', { params: { companyId: companyId } });
        dispatch(inventorySlice.actions.setAllItemsFromAllSites(response.data));
    } catch (error) {
        return dispatch(inventorySlice.actions.setAllItemsFromAllSites(initialState.items));
    }
}

export const {setAllItems, updateItem, deleteItem, updatePricing, setAllItemsFromAllSites} = inventorySlice.actions;

export default inventorySlice.reducer;
