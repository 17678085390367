import { createSlice } from '@reduxjs/toolkit';
import instance from '../utils/imsAxios';

const initialState = {
    siteSelected: JSON.parse(localStorage.getItem('selectedSite')) ?? null,
    drawerOpen: false,
    settings: {}
};

export const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        setSelectedSite: (state, action) => {
            if(action.payload) localStorage.setItem('selectedSite', JSON.stringify(action.payload));
            state.siteSelected = action.payload;
        },
        setDrawerOpen: (state, action) => {
            state.drawerOpen = !state.drawerOpen
        },
        setAllSettings: (state, action) => {
            state.settings = action.payload;
        }
    }
});

export const getAllSettings = (companyId) => async (dispatch) => {
    try {
        if (!companyId) return dispatch(globalSlice.actions.setAllSettings(initialState.settings));
        const response = await instance.get('company/settings', { params: { companyId: companyId } });
        dispatch(globalSlice.actions.setAllSettings(response.data[0]));
    } catch (error) {
        return dispatch(globalSlice.actions.setAllSettings(initialState.settings));
    }
} 



export const { setSelectedSite, setDrawerOpen, setAllSettings } = globalSlice.actions;

export default globalSlice.reducer;
