import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import React from 'react';
import { Routes, useLocation } from 'react-router-dom';
import './App.css';
import { AuthProvider } from './authentication/JWTAuthContext';
import { SnackbarProvider } from './contexts/SnackbarContext';
// import { OnlineStatusProvider } from './contexts/OnlineStatusContext';
import routes, { renderRoutes } from './routes';

function App() {
    const location = useLocation();
    
    return (
        <div className="App">
            <AuthProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    {/* <OnlineStatusProvider> */}
                    <SnackbarProvider>
                        <Routes location={location} key={location.pathname}>
                            {renderRoutes(routes)}
                        </Routes>
                    </SnackbarProvider>
                    {/* </OnlineStatusProvider> */}
                </LocalizationProvider>
            </AuthProvider>
        </div>
    );
}

export default App;
