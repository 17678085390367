import { createSlice } from '@reduxjs/toolkit';
import instance from '../../../utils/imsAxios';

const initialState = {
    sites: []
};

export const siteSlice = createSlice({
    name: 'sites',
    initialState,
    reducers: {
        setAllSites: (state, action) => {
            state.sites = action.payload;
        },
        addSite: (state, action) => {
            state.sites.push(action.payload);
        },
        deleteSite: (state, action) => {
            const index = state.sites.findIndex(site => site.siteName === action.payload);
            state.sites.splice(index, 1);
        }
    }
});

export const getAllSites = (companyId) => async (dispatch) => {
    try {
        if (!companyId) return dispatch(siteSlice.actions.setAllSites(initialState.sites));
        const response = await instance.get('/sites', { params: { companyId: companyId } });
        dispatch(siteSlice.actions.setAllSites(response.data));
    } catch (error) {
        return dispatch(siteSlice.actions.setAllSites(initialState.sites));
    }
};

export const {setAllSites, addSite, deleteSite} = siteSlice.actions;

export default siteSlice.reducer;
