import { useTheme } from '@emotion/react';
import { Autocomplete, Box, Button, Paper, Stack, TextField, Typography, alpha } from '@mui/material';
import React, { useState } from 'react'
import { AlertCircle } from 'react-feather';
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../authentication/useAuth';
import IF from '../../components/IF';
import useIdleTimeout from '../../hooks/useIdleTimeout';
import PasswordPrompt from '../../PasswordPrompt';
import { setSelectedSite } from '../../store/global'
import { getAllItems } from '../Administration/InventoryItems/slice';
import { getAllSites } from '../Administration/Sites/slice';
import { getAllUsers } from '../Administration/Users/slice';
import { getAllVehicles } from '../Administration/Vehicles/slice';
import { getAllGRVs } from '../Operations/GRVs/slice';
import { getAllActiveJobCards } from '../Operations/JobCards/slice';

const SiteSelection = () => {
    const [error, setError] = useState("");
    const { companyId, siteAccess, logout } = useAuth();
    const [site, setSite] = useState(siteAccess?.length ? siteAccess[0] : "");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const theme = useTheme();

    const handleSelectSite = () => {
        if(siteAccess.map(site => site._id).includes(site._id)) {
            dispatch(setSelectedSite(site));
            dispatch(getAllUsers(companyId));
            dispatch(getAllSites(companyId));
            dispatch(getAllItems(companyId, site.siteId));
            dispatch(getAllVehicles(companyId, site.siteId));
            dispatch(getAllActiveJobCards(companyId, site.siteId));
            dispatch(getAllGRVs(companyId, site.siteId));
            localStorage.setItem('selectedSite', JSON.stringify(site));
            navigate('/ims/operations/job-cards', { replace: true })
        }
        else {
            setError("You do not have access to this site");
        }
    }

    const [openModal, setOpenModal] = React.useState(false);

    const handleIdle = () => {
        setOpenModal(true);
    }

    const { idleTimer } = useIdleTimeout({ onIdle: handleIdle });

    const stay = () => {
        setOpenModal(false);
        idleTimer.reset();
    }

    const handleLogout = () => {
        logout();
        setOpenModal(false);
    }

    return (
        <Box
            width="100%"
            height="100vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
                background: '#f1f1f1'
            }}
        >
            <PasswordPrompt open={openModal} stay={stay} handleLogout={handleLogout} />
            <Paper
                sx={{
                    width: '300px',
                    height: '400px',
                    boxShadow: `0px 0px 10px 5px ${alpha(theme.palette.primary.dark, 0.84)}`,
                    padding: theme.spacing(4),
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}
            >
                <Stack gap={4}>
                    <Typography
                        fontSize={30}
                        color={theme.palette.primary.main}
                        textAlign="center"
                        // sx={{width: '40%'}}
                    >
                        Inventory Management System
                    </Typography>
                    {
                        siteAccess?.length ?
                        <>
                            <Autocomplete 
                                options={siteAccess ?? []}
                                value={site ?? {}}
                                size="small"
                                getOptionLabel={(option) => option.siteName ?? ""}
                                isOptionEqualToValue={(option, value) => option === value}
                                onChange={(event, option) => setSite(option)}
                                renderInput={(params) =>
                                    <TextField {...params} label="Select Site" size="small" />
                                }
                            />
                            <Button disabled={!site} onClick={handleSelectSite} variant="contained">Confirm</Button>
                            <IF condition={error}>
                                <Box display="flex" gap={2} alignItems="center">
                                    <AlertCircle size={16} color={theme.palette.error.main} />
                                    <Typography variant="caption" color="error.main">
                                        {error}
                                    </Typography>
                                </Box>
                            </IF>
                        </>
                        : 
                        <>
                            <Typography textAlign="center">You do not have access to any sites. Please contact your administrator</Typography>
                            <Button variant="contained" onClick={logout}>Sign Out</Button>
                        </>
                        
                    }
                </Stack>
            </Paper>
        </Box>
  )
}

export default SiteSelection