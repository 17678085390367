import { createSlice } from '@reduxjs/toolkit';
import instance from '../../../utils/imsAxios';

const initialState = {
    customers: [ ]
};

export const customerSlice = createSlice({
    name: 'customers',
    initialState,
    reducers: {
        setAllCustomers: (state, action) => {
            state.customers = action.payload;
        },
        updateCustomer: (state, action) => {
            const { customerNumber } = action.payload;
            const index = state.customers.findIndex(i => i.customerNumber === customerNumber);
            if(index > -1) {
                state.customers[index] = action.payload;
            }
            else {
                const customerNumbers = state.customers.map(c => +c.customerNumber.split('CS')[1]);
                const lastNum = Math.max(...customerNumbers);
                const numLength = lastNum.toString().length;
                let zeros = "";
                for (let index = 0; index < 3-numLength; index++) {
                    zeros += "0"
                }
                state.customers.push({...action.payload, balance: 0, customerNumber: `CS${zeros}${lastNum+1}`});
            }
        },
        deleteCustomer: (state, action) => {
            const index = state.customers.findIndex(u => u.customerNumber === action.payload);
            state.customers.splice(index, 1);
        }
    }
});

export const getAllCustomers = (companyId) => async (dispatch) => {
    try {
        if (!companyId) return dispatch(customerSlice.actions.setAllCustomers(initialState.customers));
        const response = await instance.get('/customers', { params: { companyId: companyId} });
        dispatch(customerSlice.actions.setAllCustomers(response.data));
    } catch (error) {
        return dispatch(customerSlice.actions.setAllCustomers(initialState.customers));
    }
}

export const {setAllCustomers, updateCustomer, deleteCustomer} = customerSlice.actions;

export default customerSlice.reducer;
