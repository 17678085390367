import { Avatar, Button, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useAuth } from '../../../authentication/useAuth';
import IconButtonWithMenu from '../../../components/IconButtonWithMenu';

const ProfileButton = ({ ...rest }) => {
    const [open, setOpen] = useState(false);
    const { user, logout } = useAuth();
    const { userName = 'user' } = user || {};

    const handleToggle = () => setOpen((prev) => !prev);

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.substr(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    return (
        <IconButtonWithMenu
            open={open}
            onClick={handleToggle}
            onMenuClose={handleToggle}
            Icon={
                <Avatar sx={{ width: '40px', height: '40px', bgcolor: stringToColor(userName) }}>
                    {userName[0].toUpperCase()}
                </Avatar>
            }
            {...rest}
        >
            <Stack alignItems="flex-end" gap={2}>
                <Button variant="text" size="small" color="error" onClick={logout}>
                    <Typography fontWeight="bold">Sign Out</Typography>
                </Button>
            </Stack>
        </IconButtonWithMenu>
    );
};

export default ProfileButton;
