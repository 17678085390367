import { createSlice } from '@reduxjs/toolkit';
import instance from '../../../utils/imsAxios';

const initialState = {
    pendingUploads: [],
    uploadsLoading: false
};

export const PendingUploadsSlice = createSlice({
    name: 'pendingUploads',
    initialState,
    reducers: {
        setPendingUploads: (state, action) => {
            state.pendingUploads = action.payload;
        },
        setUploadsInProgress: (state, action) => {
            state.uploadsLoading = action.payload;
        }
    }
});

export const getAllPendingUploads = (companyId) => async (dispatch) => {
    try {
        if (!companyId) return dispatch(PendingUploadsSlice.actions.setPendingUploads(initialState.pendingUploads));
        const response = await instance.get('/pendingUploads', { params: { companyId: companyId } });
        dispatch(PendingUploadsSlice.actions.setPendingUploads(response.data));
    } catch (error) {
        return dispatch(PendingUploadsSlice.actions.setPendingUploads(initialState.pendingUploads));
    }
};

export const {setPendingUploads, setUploadsInProgress} = PendingUploadsSlice.actions;

export default PendingUploadsSlice.reducer;
