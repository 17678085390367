import { configureStore } from '@reduxjs/toolkit';
import globalReducer from './global';
import userReducer from '../views/Administration/Users/slice';
import siteReducer from '../views/Administration/Sites/slice';
import inventoryReducer from '../views/Administration/InventoryItems/slice';
import vehicleReducer from '../views/Administration/Vehicles/slice';
import customerReducer from '../views/Administration/Customers/slice';
import jobCardReducer from '../views/Operations/JobCards/slice';
import grvReducer from '../views/Operations/GRVs/slice';
import salesDataReducer from '../views/Reports/SalesAnalysis/slice';
import pendingUploadsReducer from '../layouts/MainLayout/TopBar/slice';

export const store = configureStore({
    reducer: {
        global: globalReducer,
        users: userReducer,
        sites: siteReducer,
        inventory: inventoryReducer,
        vehicles: vehicleReducer,
        customers: customerReducer,
        jobCards: jobCardReducer,
        grvs: grvReducer,
        salesData: salesDataReducer,
        pendingUploads: pendingUploadsReducer
    }
});
