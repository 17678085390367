import { createSlice } from '@reduxjs/toolkit';
import instance from '../../../utils/imsAxios';

const initialState = {
    roles: [],
    users: [],
    permissions: [
        {
            module: 'users',
            view: false,
            edit: false,
            del: false
        },
        {
            module: 'sites',
            view: false,
            edit: false,
            del: false
        },
        {
            module: 'inventory-items',
            view: false,
            edit: false,
            del: false
        },
        {
            module: 'customers',
            view: false,
            edit: false,
            del: false
        },
        {
            module: 'vehicles',
            view: false,
            edit: false,
            del: false
        },
        {
            module: 'job-cards',
            view: false,
            edit: false,
            del: false
        },
        {
            module: 'goods-received-vouchers',
            view: false,
            edit: false,
            del: false
        },
        {
            module: 'payments',
            view: false,
            edit: false,
            del: false
        },
        {
            module: 'discounts',
            view: false,
            edit: false,
            del: false
        },
        {
            module: 'sub-items',
            view: false,
            edit: false,
            del: false
        },
        {
            module: 'inventory-movements',
            view: false,
            edit: false,
            del: false
        },
        {
            module: 'inventory-levels',
            view: false,
            edit: false,
            del: false
        },
        {
            module: 'sales-analysis',
            view: false,
            edit: false,
            del: false
        },
        {
            module: 'vehicle-services',
            view: false,
            edit: false,
            del: false
        },
        {
            module: 'diesel-usage',
            view: false,
            edit: false,
            del: false
        },
        {
            module: 'payments-report',
            view: false,
            edit: false,
            del: false
        }
    ]
};


export const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setAllUsers: (state, action) => {
            state.users = action.payload;
        },
        addUser: (state, action) => {
            state.users.push(action.payload);
        },
        deleteUser: (state, action) => {
            const index = state.users.findIndex(u => u.userName === action.payload);
            state.users.splice(index, 1);
        },
        setAllRoles: (state, action) => {
            state.roles = action.payload;
        },
        setRolePermissions: (state, action) => {
            const { roleDescription, permissions } = action.payload;
            const index = state.roles.findIndex(r => r.roleDescription === roleDescription);
            state.roles[index].permissions = permissions;
        },
        addRole: (state, action) => {
            state.roles.push(
                {
                    roleDescription: action.payload,
                    permissions: initialState.permissions
                }
            )
        },
        deleteRole: (state, action) => {
            const index = state.roles.findIndex(r => r.roleDescription === action.payload);
            state.roles[index].delete = true;
        }
    }
});

export const getAllUsers = (companyId) => async (dispatch) => {
    try {
        if (!companyId) return dispatch(userSlice.actions.setAllUsers(initialState.allUsers));
        const response = await instance.get('/users', { params: { companyId: companyId } });
        dispatch(userSlice.actions.setAllUsers(response.data));
    } catch (error) {
        return dispatch(userSlice.actions.setAllUsers(initialState.allUsers));
    }
};

export const getAllRoles = (companyId) => async (dispatch) => {
    try {
        if (!companyId) return dispatch(userSlice.actions.setAllRoles(initialState.roles));
        const response = await instance.get('/users/roles', { params: { companyId: companyId } });
        dispatch(userSlice.actions.setAllRoles(response.data));
    } catch (error) {
        return dispatch(userSlice.actions.setAllRoles(initialState.roles));
    }
} 

export const {setAllUsers, addUser, deleteUser, setAllRoles, setRolePermissions, addRole, deleteRole} = userSlice.actions;

export default userSlice.reducer;
