import { createSlice } from '@reduxjs/toolkit';
import instance from '../../../utils/imsAxios';

const initialState = {
   salesData: []
};

export const SalesAnalysisSlice = createSlice({
    name: 'salesData',
    initialState,
    reducers: {
        setSalesData: (state, action) => {
            state.salesData = action.payload;
        }
    }
});

export const getSalesData = (companyId, siteId) => async (dispatch) => {
    try {
        if (!companyId) return dispatch(SalesAnalysisSlice.actions.setSalesData(initialState.sites));
        const response = await instance.get('/salesData', { params: { companyId: companyId, siteId: siteId } });
        dispatch(SalesAnalysisSlice.actions.setSalesData(response.data));
    } catch (error) {
        return dispatch(SalesAnalysisSlice.actions.setSalesData(initialState.sites));
    }
};

export const {setSalesData} = SalesAnalysisSlice.actions;

export default SalesAnalysisSlice.reducer;
