import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { UploadCloud } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../../authentication/useAuth';
import IconButtonWithMenu from '../../../components/IconButtonWithMenu';
import instance from '../../../utils/imsAxios';
import { setPendingUploads, setUploadsInProgress } from './slice';

const UploadButton = ({ ...rest }) => {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const { companyId } = useAuth();
    const { pendingUploads, uploadsLoading } = useSelector(state => state.pendingUploads);
    
    const handleToggle = () => setOpen((prev) => !prev);

    const uploadPendingUpdates = async () => {
        setOpen(false);
        dispatch(setUploadsInProgress(true));
        const { data } = await instance.post('/pendingUploads/upload', { companyId: companyId });
        dispatch(setPendingUploads(data));
        dispatch(setUploadsInProgress(false));
    };
    
    return (
        pendingUploads.length ?
        <IconButtonWithMenu
            open={uploadsLoading ? false : open}
            onClick={handleToggle}
            onMenuClose={handleToggle}
            Icon={
                !uploadsLoading ? <UploadCloud color='white' /> : <CircularProgress size={24} thickness={6} sx={{color: 'white'}} />
            }
            {...rest}
        >
            <Stack alignItems="flex-end" gap={2}>
                <Typography>
                    {pendingUploads.length} change(s) have not been uploaded yet
                </Typography>
                <Button variant='outlined' onClick={() => uploadPendingUpdates()}>Upload Now</Button>
            </Stack>
        </IconButtonWithMenu> 
        : null
    );
};

export default UploadButton;
