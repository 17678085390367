import { createSlice } from '@reduxjs/toolkit';
import instance from '../../../utils/imsAxios';

const initialState = {
    vehicles: []
};

export const vehicleSlice = createSlice({
    name: 'vehicles',
    initialState,
    reducers: {
        setAllVehicles: (state, action) => {
            state.vehicles = action.payload;
        }
    }
});

export const getAllVehicles = (companyId, siteId) => async (dispatch) => {
    try {
        if (!companyId) return dispatch(vehicleSlice.actions.setAllVehicles(initialState.vehicles));
        const response = await instance.get('/vehicles', { params: { companyId: companyId, siteId: siteId} });
        dispatch(vehicleSlice.actions.setAllVehicles(response.data));
    } catch (error) {
        return dispatch(vehicleSlice.actions.setAllVehicles(initialState.vehicles));
    }
};

export const {setAllVehicles, addVehicle, deleteVehicle} = vehicleSlice.actions;

export default vehicleSlice.reducer;
