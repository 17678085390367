import { Popover, ToggleButton } from '@mui/material';
import React, { useRef } from 'react';

const IconButtonWithMenu = ({
    onClick = () => {},
    open = false,
    Icon = null,
    onMenuClose = () => {},
    children,
    sx,
    ...rest
}) => {
    const anchorEl = useRef(null);
    return (
        <>
            <ToggleButton
                // aria-describedby="settingsPopover"
                onClick={onClick}
                size="small"
                ref={anchorEl}
                selected={open}
                value="settings"
                sx={{
                    ...sx,
                    border: 'none',
                    borderRadius: '50%'
                }}
                {...rest}
            >
                {Icon}
            </ToggleButton>
            <Popover
                // id="settingsPopover"
                open={open}
                anchorEl={anchorEl.current}
                onClose={onMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                PaperProps={{
                    sx: {
                        p: '8px'
                    }
                }}
            >
                {children}
            </Popover>
        </>
    );
};

export default IconButtonWithMenu;
