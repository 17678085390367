import { Paper, Stack, TextField, Typography, useTheme, alpha, Button} from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { AlertCircle } from 'react-feather';
import { useAuth } from '../../authentication/useAuth';
import IF from '../../components/IF';

const Login = () => {
    const { login } = useAuth();
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const theme = useTheme();

    const handleLogin = async () => {
        try {
            await login({ userName, password });
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <Box
            width="100%"
            height="100vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
                background: '#f1f1f1'
            }}
        >
            <Paper
                sx={{
                    width: '300px',
                    height: '400px',
                    boxShadow: `0px 0px 10px 5px ${alpha(theme.palette.primary.dark, 0.84)}`,
                    padding: theme.spacing(4),
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}
            >
                <Stack width="100%" direction="column" gap={4} justifyContent="space-between" alignItems="center">
                    <Typography
                        fontSize={30}
                        color={theme.palette.primary.main}
                        textAlign="center"
                        // sx={{width: '40%'}}
                    >
                        Inventory Management System
                    </Typography>
                    <Stack gap={4}>
                        <TextField label="Username" value={userName} onChange={(event) => setUserName(event.target.value)} />
                        <TextField label="Password" type="password" value={password} onChange={(event) => setPassword(event.target.value)} />
                        <Button onClick={handleLogin} variant="contained">Login</Button>
                        <IF condition={error}>
                            <Box display="flex" gap={2} alignItems="center">
                                <AlertCircle size={16} color={theme.palette.error.main} />
                                <Typography variant="caption" color="error.main">
                                    {error}
                                </Typography>
                            </Box>
                        </IF>
                    </Stack>
                </Stack>
            </Paper>
    </Box>
  )
}

export default Login